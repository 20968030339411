<template>
  <v-container fluid>
    <loader v-if="loading" />

    <section v-else-if="unverifed">
      <v-row class="mt-6" justify="center">
        <v-col cols="12" sm="11" md="10" lg="8" xl="6">
          <v-card class="pa-8 rounded-xl">
            <div class="text-center text-h5 mb-4">
              ✨ Conteúdo Especial para Usuários Verificados!
            </div>

            <div class="text-center text-h6 font-weight-regular mb-8">
              Este conteúdo é reservado para nossos usuários verificados. Se
              você é um criador de conteúdo ou influenciador, faça a verificação
              para aproveitar este material exclusivo! 😉
            </div>

            <v-row>
              <v-col cols="6">
                <v-btn
                  class="text-none rounded-lg text-body-1 font-weight-bold"
                  color="primary"
                  href="https://pay.kiwify.com.br/yjs0iKn"
                  target="_blank"
                  large
                  block
                >
                  Quero ser UGC
                </v-btn>
              </v-col>

              <v-col cols="6">
                <v-btn
                  class="text-none rounded-lg text-body-1 font-weight-bold"
                  color="primary"
                  href="https://monetizainflu.com.br/"
                  target="_blank"
                  large
                  block
                >
                  Quero ser INFLU
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </section>

    <section v-else>
      <div
        class="text-h6 text-sm-h5 font-weight-bold text-center text-sm-left mb-6"
      >
        Campanhas
      </div>

      <TabNavigation :tabs="tabs" type="path" keyValue="/campaigns" />

      <v-divider class="surface mb-4" />

      <router-view />
    </section>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import TabNavigation from "@/components/tabNavigation/TabNavigation.vue";

export default {
  name: "Campaigns",

  components: {
    TabNavigation,
  },

  data() {
    return {
      loading: true,
      unverifed: false,
      tabs: [
        {
          label: "Novas campanhas",
          value: "/new-campaigns",
        },
        {
          label: "Minhas aplicações",
          value: "/my-applications",
        },
      ],
    };
  },

  beforeMount() {
    this.handleAllowed();
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    async handleAllowed() {
      if (!this.user.maluFlag && !this.user.carolFlag) {
        this.unverifed = true;
        this.loading = false;

        return;
      }

      if (!this.user.creatorData) {
        this.handleAlert("Complete seu cadastro creator", "info");
        this.$router.push({ path: "/settings/creator" });
        return;
      }

      this.loading = false;
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
